//
// Color system
//
$white:         #fff;
$magenta: #ec008c;/*236,0,140*/
$jaune: #fff200;/*255,242,0*/
$bleu: #00aeef;/*0,174,239*/
$vert: #00a651;
$yellow: $jaune;
$blue: $bleu;
$green: $vert;
$black:         #000;
$gray-200:      #e9ecef;
$gray-300 : #f1f1f1;
$gray-400: #ced4da;
$gray-500: #9d9d9c;
$gray-900: #2c2e35;
$primary:       $bleu;

// Options
$enable-rounded:            true;
$enable-shadows:            true;

//
// Links
//
// Style anchor elements.


$link-hover-decoration:     none;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      "Helvetica Neue LT Std", "Helvetica Neue", 'Roboto', Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family:        $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`


$h1-font-size:                $font-size-base * 1.375;
$h2-font-size:                $font-size-base * 1.25;
$h3-font-size:                $font-size-base * 1.125;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.9375;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;

$small-font-size:             $font-size-base * 0.875;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;


//Cards
$card-cap-bg: $white;
$card-border-width: 0;